@import "~animate.css/animate.css";

html {
  margin: 0;
  height: 100vh;
}

body {
  margin: 0;
  height: 100vh;
  scroll-snap-type: y proximity;
  overflow: scroll;
  overflow-y: scroll;
  scroll-padding-top: 150px;
  text-align: center;
  font-family: "Cabin Sketch", cursive;
}
.first {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}
h1 {
  margin: 0;
}
h2 {
  font-size: 35px;
}

section {
  padding: 1em;
  height: 100vh;
  width: 100%;
  box-sizing: border-box;
}

section:nth-of-type(1) {
  background: url("https://i.imgur.com/4d3nIpzh.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  font-size: 50px;
}

section:nth-of-type(2) {
  background: #3c91e6;
  font-size: 28px;
}

section:nth-of-type(3) {
  background: #9fd356;
  font-size: 30px;
}

section:nth-of-type(4) {
  background: #ddf7a2;
  font-size: 30px;
}

.wyze:hover {
  text-shadow: 2px 2px 3px white;
  background-color: white;
  background: white;
  box-shadow: 4px 4px 12px rgba(255, 255, 255);
}

.time {
  position: relative;
}
.pics {
  display: flex;
  justify-content: space-evenly;
}

header {
  height: 70px;
  background: rgba(52, 46, 55, 0.8);
  color: #fafffd;
  top: 0;
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: center;
}

ul {
  margin: 0;
  display: flex;
  font-size: 40px;
  justify-content: space-evenly;
  list-style: none;
  text-decoration: none;
  width: 100%;
}

a {
  text-decoration: none;
  color: white;
}

p {
  font-size: 26px;
}

.info {
  font-size: 24px;
  border: 2px solid #b0bec5;
  border-radius: 8px;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.3);
  background: white;
}

.icons {
  display: flex;
  justify-content: space-evenly;
}

.ref {
  display: flex;
  justify-content: space-around;
  flex-direction: column-reverse;
  padding: 1em;
}
footer {
  background: #858187;
  color: white;
}

.email:hover {
  background-color: gold;
}
.email {
  padding: 1em;
}
#projects {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
}

img {
  padding: 1em;
}
#about {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.heading {
  position: relative;
  top: -40px;
}
#contact {
  display: flex;
  flex-direction: column;
}

@media (max-width: 1100px) {
  img {
    height: 25vh;
  }
}

@media (max-width: 700px) {
  section:nth-of-type(1) {
    background: url("https://i.imgur.com/4d3nIpzl.jpg");
    background-position: center;
    background-size: cover;
    font-size: 25px;
  }

  .time {
    position: relative;
    bottom: 100px;
    width: 100vw;
    display: flex;
    left: 150px;
    height: 10vh;
  }
  header {
    width: 100%;
  }

  .name {
    position: relative;
    left: -60px;
  }
  .follow {
    position: relative;
    left: -50px;
  }

  img {
    height: 7vh;
  }
  p {
    font-size: 18px;
  }
  .pics {
    width: 100vw;
    flex-flow: column;
  }
  #projects {
    display: flex;
    justify-content: center;
  }
  img {
    padding: 0;
  }
  .info {
    width: fit-content;
    font-size: 20px;
  }
  .email {
    display: flex;
    font-size: 24px;
    justify-content: center;
  }
}
